import {
  Article,
  BlockContent,
  BlockContentBuilder,
  createUrlBuilder,
  JsonLD,
  LeazardForm,
} from "@nettbureau/gatsby-theme-katalog";
import { graphql } from "gatsby";
import { get } from "lodash";
import React, { ReactNode } from "react";
import { RelatedProducts } from "../components";
import no from "../messages";

interface Props {
  data: {
    company: any;
  };
  pageContext: {
    id: string;
  };
}

export default ({ data }: Props) => {
  const { company } = data;
  const thumbnail = get(company, ["cms", "logo"]);
  const content = resolveContent(company);
  return (
    <Article
      sidebar={
        <LeazardForm id={get(company, ["cms", "settings", "leazardFormId"])} />
      }
    >
      <JsonLD
        schema={{
          "@context": "https://schema.org",
          "@type": "Organization",
          url: company.url,
          name: company.companyName,
          logo: thumbnail
            ? {
                "@type": "ImageObject",
                url: createUrlBuilder(thumbnail, {
                  height: 50,
                  width: 50,
                }).url(),
                height: 50,
                width: 50,
              }
            : null,
          contactPoint: {
            "@type": "ContactPoint",
            contactType: "customer support",
            contactOption: {
              "@type": "ContactPointOption",
            },
          },
          owns: company.products.map((p: any) => ({
            "@type": "Product",
            name: p.productName,
          })),
        }}
      />
      <CompanyTemplate title={company.companyName}>
        <BlockContent content={content} />
        <div className="clearfloat" />
        <RelatedProducts
          title={no.string("TEMPLATE_products_title", company)}
          description={no.string("TEMPLATE_products_description", company)}
          products={company.products}
        />
      </CompanyTemplate>
    </Article>
  );
};

export type CompanyTemplateProps = {
  title: string;
  children: ReactNode;
};

export const CompanyTemplate = ({ title, children }: CompanyTemplateProps) => {
  return (
    <div>
      <h1>{title}</h1>
      {children}
    </div>
  );
};

function resolveContent(company: any) {
  return BlockContentBuilder.from(get(company, ["cms", "article"]))
    .with(
      {
        _type: "contactDetails",
        organizationName: company.companyName,
        organizationNumber: company.organizationNumber,
        logo: {
          ...get(company, ["cms", "logo"]),
          options: { width: 350 },
        },
        ...get(company, ["cms", "contactDetails"]),
      },
      { position: 0 }
    )
    .sanitize();
}

export const query = graphql`
  query ($id: String!) {
    company: stromCompany(id: { eq: $id }) {
      companyName
      organizationNumber
      url
      # This property also exists...
      # singleBillCompanies
      totalProducts
      products {
        id
        productName
        slug
      }
      cms {
        settings {
          leazardFormId
        }
        logo {
          ...KatalogSanityLogo
        }
        contactDetails {
          _type
          organizationName
          organizationNumber
          address
          email
          phone
          website
        }
        article: _rawArticle
      }
    }
  }
`;
